import * as React from "react";
import LoginForm from "./LoginForm";
import RegistrationFrom from "./RegistrationFrom";

interface Props {
  type: string;
  handleSubmit(form: { email: string; password: string; name?: string }): void;
}

class Auth extends React.Component<Props, null> {
  public render() {
    if (this.props.type === "LOGIN") {
      return <LoginForm onSubmit={this.props.handleSubmit} />;
    }
    return <RegistrationFrom onSubmit={this.props.handleSubmit} />;
  }
}

export default Auth;
