import * as React from "react";
import Typography from "src/components/Typography";
import authModuleCss from "./auth.module.css";

interface Props {
  active: string;
  setLoginType(): void;
  setREGISTEREDType(): void;
}

class ToggleSection extends React.Component<Props> {
  public getClassName = (type) => {
    if (type === this.props.active) {
      return "active";
    }
    return "passive";
  };

  public render() {
    const { props } = this;

    return (
      <div className={authModuleCss.authToggle}>
        <div className={authModuleCss[this.getClassName("LOGIN")]}>
          <Typography onClick={props.setLoginType} black type="boldText">
            ВХОД
          </Typography>
        </div>
        <div className={authModuleCss[this.getClassName("REGISTERED")]}>
          <Typography onClick={props.setREGISTEREDType} black type="boldText">
            РЕГИСТРАЦИЯ
          </Typography>
        </div>
      </div>
    );
  }
}

export default ToggleSection;
