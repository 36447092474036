import { LocationContext } from "@reach/router";
import { navigate } from "gatsby";
import * as React from "react";
import { connect } from "react-redux";
import { SubmissionError } from "redux-form";
import { actions as userActions } from "src/store/User";
import { PageProps, User } from "src/types";
import Auth from "./Auth";
import authModuleCss from "./auth.module.css";
import HelloSection from "./HelloSection";
import SocialAuth from "./SocialAuth";
import ToggleSection from "./ToggleSection";

interface ConnectStateProps {
  user: User;
  userFetching: boolean;
}

interface ConnectDispatchProps {
  login(email: string, password: string): Promise<boolean>;
  sendUser(LoginData): Promise<boolean>;
}

type LoginData = {
  email: string;
  password: string;
  name?: string;
  lastName?: string;
};

interface Props extends ConnectStateProps, ConnectDispatchProps, PageProps {
  type: "REGISTERED" | "LOGIN";
  closeTo: string;
}

class AuthContainer extends React.Component<Props & LocationContext> {
  public async componentDidMount() {
    await this.redirectOnLogin();
  }

  public async componentDidUpdate() {
    await this.redirectOnLogin();
  }

  public render() {
    return (
      <div className={authModuleCss.AuthContainer}>
        <div className={authModuleCss.helloSectionMainWrapper}>
          <HelloSection type={this.props.type} />
        </div>
        <div className={authModuleCss.formSection}>
          <ToggleSection
            active={this.props.type}
            setLoginType={this.setLoginType}
            setREGISTEREDType={this.setREGISTEREDType}
          />
          <Auth handleSubmit={this.handleSubmit} type={this.props.type} />
          <SocialAuth />
        </div>
      </div>
    );
  }

  public handleSubmit = async (form: LoginData) => {
    let isValid;
    if (this.props.type === "LOGIN") {
      isValid = await this.props.login(form.email, form.password);
    } else {
      isValid = await this.props.sendUser({
        email: form.email,
        name: form.name,
        password: form.password,
        lastName: form.lastName,
      });
    }
    if (!isValid) {
      throw new SubmissionError({});
    }
    await this.redirectOnLogin();
  };

  private redirectOnLogin = async () => {
    if (this.props.user && this.props.user.email) {
      await navigate(this.props.location?.state?.closeTo || "/", {
        state: {
          noScroll: true,
        },
      });
    }
  };

  private setLoginType = async () => {
    await navigate("/login", {
      state: {
        modal: !!this.props.location?.state?.modal,
        noScroll: true,
        closeTo: this.props.location?.state?.closeTo,
      },
    });
  };
  private setREGISTEREDType = async () => {
    await navigate("/register", {
      state: {
        modal: !!this.props.location?.state?.modal,
        noScroll: true,
        closeTo: this.props.location?.state?.closeTo,
      },
    });
  };
}

const mapStateToProps = (state) => ({
  // tslint:disable no-unsafe-any
  user: state.user.user,
  userFetching: state.user.fetching,
  // tslint:enable no-unsafe-any
});

const mapActionsToProps = {
  login: userActions.login,
  sendUser: userActions.sendUser,
};

export default connect<ConnectStateProps, ConnectDispatchProps>(
  mapStateToProps,
  // @ts-ignore
  mapActionsToProps
)(AuthContainer);
