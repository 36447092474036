import * as React from "react";
import { InjectedFormProps, reduxForm } from "redux-form";
import layoutModuleCss from "src/components/Layout/layout.module.css";

import { Link } from "gatsby";
import Expand from "react-expand-animated";
import Button from "src/components/Button";
import TextInput from "src/components/TextInput";
import Typography from "src/components/Typography";
import authModuleCss from "./auth.module.css";

interface Props extends InjectedFormProps<FormData> {}

type FormData = {
  email: string;
  password: string;
};

class LoginForm extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <form onSubmit={props.handleSubmit}>
        <div>
          <TextInput name="email" label="Email" black required />
          <TextInput
            name="password"
            type="password"
            label="Пароль"
            required
            black
          />
        </div>
        <div className={layoutModuleCss.formInfo}>
          <Expand open={props.submitFailed}>
            <Typography type="boldText" black>
              Неверный email или пароль
            </Typography>
          </Expand>
        </div>
        <div className={authModuleCss.formButton}>
          <Button type="submit" disabled={!props.valid || props.submitting}>
            ВХОД
          </Button>
        </div>
        <Link
          style={{
            textAlign: "center",
            margin: "12px auto",
            display: "block",
          }}
          to="/forget-password/"
        >
          <Typography type="boldText" black>
            ЗАБЫЛИ ПАРОЛЬ?
          </Typography>
        </Link>
      </form>
    );
  }
}

const validate = (values: { email: string; password: string }) => {
  const errors: { email?: string; password?: string } = {};
  const requiredFields = ["email", "password"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Обязательное поле";
      return;
    }
    // tslint:disable-next-line: no-unsafe-any
    if (values[field].length < 2) {
      errors[field] = "Недостаточная длинна значения";
    }
  });

  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default reduxForm<FormData>({
  form: "LoginForm", // a unique identifier for this form
  validate,
})(LoginForm);
