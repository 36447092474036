import * as React from "react";
import Expand from "react-expand-animated";
import { InjectedFormProps, reduxForm } from "redux-form";
import Button from "src/components/Button";
import CheckBoxField from "src/components/checkBoxField";
import layoutModuleCss from "src/components/Layout/layout.module.css";
import TextInput from "src/components/TextInput";
import Typography from "src/components/Typography";
import authModuleCss from "./auth.module.css";
import AgreementCheckBoxField from "../AgreementCheckBoxField";

interface Props extends InjectedFormProps<FormData> {}

type FormData = {
  email: string;
  password: string;
  name: string;
  lastName: string;
};

class RegistrationFrom extends React.Component<Props> {
  public render() {
    const { props } = this;
    return (
      <form onSubmit={props.handleSubmit}>
        <div>
          <TextInput name="lastName" label="Фамилия" black required />
          <TextInput name="name" label="Имя" black required />
          <TextInput name="email" label="email" black required />
          <TextInput
            name="password"
            type="password"
            label="Пароль"
            black
            required
          />
        </div>
        <AgreementCheckBoxField name={"agreement"} />
        <Expand open={props.submitFailed}>
          <div className={layoutModuleCss.formInfo}>
            <Typography type="boldText" black>
              Ошибка регистрации
            </Typography>
          </div>
        </Expand>
        <div className={authModuleCss.formButton}>
          <Button type="submit" disabled={!props.valid || props.submitting}>
            ЗАРЕГИСТРИРОВАТЬСЯ
          </Button>
        </div>
      </form>
    );
  }
}

const validate = (values: { email: string; password: string }) => {
  const errors: { email?: string; password?: string } = {};
  const requiredFields = ["email", "password", "name", "agreement", "lastName"];
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = "Обязательное поле";
      return;
    }
    // tslint:disable-next-line: no-unsafe-any
    if (values[field].length < 2) {
      errors[field] = "Недостаточная длинна значения";
    }
  });
  if (
    values.email &&
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = "Invalid email address";
  }
  return errors;
};

export default reduxForm<FormData>({
  form: "RegistrationFrom", // a unique identifier for this form
  validate,
})(RegistrationFrom);
