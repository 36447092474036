import * as React from "react";
import Typography from "src/components/Typography";
import loginMascot from "src/images/LoginIcon.svg";
import authModuleCss from "./auth.module.css";

interface Props {
  type: string;
}

class HelloSection extends React.Component<Props> {
  public render() {
    if (this.props.type === "LOGIN") {
      return this.renderSection({
        header: "С возращением :)",
        subHeader: "Мы по тебе скучали",
        link: loginMascot,
      });
    }
    return this.renderSection({
      header: "Здравствуй :)",
      subHeader: "Мы очень тебе рады ",
      link: loginMascot,
    });
  }

  public renderSection({
    header,
    subHeader,
    link,
  }: {
    header: string;
    subHeader: string;
    link: string;
  }) {
    return (
      <div className={authModuleCss.helloSection}>
        <div>
          <Typography type="h4">{header}</Typography>
        </div>
        <div>
          <Typography type="h4">{subHeader}</Typography>
        </div>
        <div className={authModuleCss.mascotWrapper}>
          <img src={link} alt={header} />
        </div>
      </div>
    );
  }
}

export default HelloSection;
